import axios from 'axios'

export default {
  getAllChapter () {
    return axios.get(process.env.VUE_APP_USER_CHAPTER)
  },

  getCourseChapter (id) {
    return axios.get(process.env.VUE_APP_USER_CHAPTER + '/course/' + id)
  },

  createChapter (data) {
    return axios.post(process.env.VUE_APP_USER_CHAPTER, data)
  },

  updateChapter (data, id) {
    return axios.put(process.env.VUE_APP_USER_CHAPTER + '/' + id, data)
  },

  getChapterDetails (id) {
    return axios.get(process.env.VUE_APP_USER_CHAPTER + '/' + id)
  },

  deleteChapter (id) {
    return axios.delete(process.env.VUE_APP_USER_CHAPTER + '/' + id)
  },

  getCourseAllChaptersWithQuiz (courseId, profileId) {
    return axios.get(process.env.VUE_APP_USER_CHAPTER + '/course/' + courseId + '/' + profileId)
  },

  readChapter (data) {
    return axios.post(process.env.VUE_APP_USER_CHAPTER_READ, data)
  }
}
