
import { defineComponent, onMounted, ref, reactive, UnwrapRef } from 'vue'
import {
  HomeOutlined,
  HeartOutlined,
  UnorderedListOutlined,
  WarningOutlined,
  RedoOutlined,
  PlayCircleOutlined,
  StopOutlined,
  PauseCircleOutlined,
  CaretRightFilled,
  CheckOutlined
} from '@ant-design/icons-vue'
import { useRoute } from 'vue-router'
import { notification } from 'ant-design-vue'
import moment from 'moment'

import router from '../../../router'
import chapterService from '../../../services/chapter'
import courseService from '../../../services/course'
import quizService from '../../../services/quiz'
import certficateService from '../../../services/certficate'
import commonServices from '../../../services/common'

import IChapter from './../../../interface/chapter'
import ICourse from './../../../interface/course'

declare const responsiveVoice

interface Answer {
  _id?: string;
  name: string;
  correct?: boolean;
  higlightClass?: string;
}

interface Question {
  _id?: string;
  question: string;
  options: Answer[];
  type: string;
  correct?: [];
}
interface Quiz {
  _id?: string;
  name: string;
  questions: Question[];
}
interface QuizForm {
  quiz: Quiz[];
}
export default defineComponent({
  components: {
    HomeOutlined,
    UnorderedListOutlined,
    HeartOutlined,
    WarningOutlined,
    RedoOutlined,
    PlayCircleOutlined,
    StopOutlined,
    PauseCircleOutlined,
    CaretRightFilled,
    CheckOutlined
  },
  setup () {
    const params = new URLSearchParams(window.location.search).get('loginAs')
    const loginAs = ref(params ? '?loginAs=' + params : '')
    const chapters = ref<Array<IChapter>>([])
    const course = ref<Array<ICourse>>([])
    const route = useRoute()
    const courseId = ref<string>(route.params.id as string)
    const activeCourseIndex = ref(0)
    const coursePercentage = ref(0)
    const disableNext = ref<boolean>(false)
    const courseCompleted = ref<boolean>(false)
    const enableSideMenuClick = ref<boolean>(false)
    const previewCourse = ref<boolean>(false)
    const loading = ref<boolean>(false)
    const activeMenu = ref(0)
    const questionFormRef = ref()
    const attendedQuestions = ref([])
    const validateAnswerData = ref({ questions: [] })
    const totalQuestions = ref(0)
    const previousQuestionId = ref<string>()
    const validatedResponce = ref([])
    const attempt = ref()
    const playFlag = ref<boolean>(true)
    const pauseFlag = ref<boolean>(false)
    const resumeFlag = ref<boolean>(false)
    const stopFlag = ref<boolean>(false)
    const notify = async (
      message: string,
      description: string,
      type: string
    ) => {
      notification[type]({
        message,
        description
      })
    }
    const questionForm: UnwrapRef<QuizForm> = reactive({
      quiz: []
    })
    const scrollTo = async (id) => {
      document.getElementById(id).scrollIntoView({
        behavior: 'smooth'
      })
    }
    const readChapter = async chapterId => {
      try {
        const profile = commonServices.getCurrentProfile()
        const data = {
          profile: profile._id,
          course: courseId.value,
          chapter: chapterId
        }
        await chapterService.readChapter(data)
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const validateAnswers = async (index?) => {
      try {
        loading.value = true
        const profile = commonServices.getCurrentProfile()
        if (index) {
          for (const quiz of chapters.value[index].quizzes) {
            const chapterQuiz: any = quiz
            const responce = await quizService.validateAnswers(
              validateAnswerData.value,
              chapterQuiz._id,
              profile._id
            )
            validatedResponce.value = responce.data.output
            attempt.value = responce.data.attempt
            for (const question of chapterQuiz.questions) {
              question.question = question.name
              const correctAnswer: any = []
              const q = validatedResponce.value.filter(
                a => a.question === question._id
              )
              if (question.attemptOption && question.attemptOption.length > 0) {
                for (const o of question.attemptOption) {
                  correctAnswer.push(o.option)
                }
              }
              if (q.length > 0) {
                for (const option of question.options) {
                  const answer = q[0].answers.filter(
                    b => b.option === option._id
                  )
                  if (answer.length > 0) {
                    if (question.type === 'Single Choice') {
                      if (option._id === answer[0].option) {
                        option.correct = true
                        if (attempt.value.mark >= 80) {
                          option.higlightClass = 'correct'
                        }
                      }
                    } else {
                      for (const a of answer) {
                        if (option._id === a.option) {
                          option.correct = true
                          if (attempt.value.mark >= 80) {
                            option.higlightClass = 'correct'
                          }
                        }
                      }
                    }
                  }
                }
              }
              question.correct = correctAnswer
            }
            questionForm.quiz = []
            questionForm.quiz.push(quiz)
          }
        }
        loading.value = false
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const generateQuizForm = async index => {
      validateAnswerData.value.questions = []
      totalQuestions.value = 0
      if (
        chapters.value[index] &&
        chapters.value[index].quizzes &&
        chapters.value[index].quizzes.length > 0
      ) {
        for (const quiz of chapters.value[index].quizzes) {
          const chapterQuiz: any = quiz
          const questions: Question[] = []
          for (const question of chapterQuiz.questions) {
            disableNext.value = true
            totalQuestions.value++
            const options: Answer[] = []
            for (const option of question.options) {
              options.push({
                _id: option._id,
                name: option.name,
                correct: false,
                higlightClass: ''
              })
            }
            const correctAnswer: any = []
            if (question.attemptOption && question.attemptOption.length > 0) {
              attendedQuestions.value.push(question._id)
              const validateData = { question: question._id, answers: [] }
              for (const option of question.attemptOption) {
                correctAnswer.push(option.option)
                validateData.answers.push({
                  correct: false,
                  option: option.option,
                  higlightClass: ''
                })
              }
              validateAnswerData.value.questions.push(validateData)
            }
            questions.push({
              _id: question._id,
              question: question.name,
              options: options,
              type: question.type,
              correct: correctAnswer
            })
          }
          const q: Quiz = {
            _id: chapterQuiz._id,
            name: chapterQuiz.name,
            questions: questions
          }
          questionForm.quiz.push(q)
        }
        // get validated answers
        await validateAnswers(index)
        // Enable next chapter button
        if (attendedQuestions.value.length === totalQuestions.value) {
          disableNext.value = false
        }
      } else {
        questionForm.quiz = []
      }
    }
    const generateCertficate = async mark => {
      try {
        const profile = commonServices.getCurrentProfile()
        await certficateService.generateCertficate({
          course: courseId.value,
          profile: profile._id,
          date: moment.utc().format(),
          exp_date: moment.utc().add(1, 'year'),
          grade: mark
        })
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const calculatePercentage = async () => {
      coursePercentage.value = +(100 / chapters.value.length).toFixed(1)
      if (coursePercentage.value > 100) coursePercentage.value = 100
    }
    const navigateToUnreadChapter = async () => {
      let i = 0
      for (const chapter of chapters.value) {
        if (chapter.read) {
          activeCourseIndex.value++
          activeMenu.value = i + 1
          const percentage = 100 / chapters.value.length
          coursePercentage.value = +(
            coursePercentage.value + percentage
          ).toFixed(1)
          if (coursePercentage.value > 100) coursePercentage.value = 100
          await generateQuizForm(i + 1)
        }
        i++
      }
      if (chapters.value.length === activeMenu.value) {
        courseCompleted.value = true
        enableSideMenuClick.value = true
        previewCourse.value = false
      }
    }
    const getCourseAllChaptersWithQuiz = async (courseId, profileId) => {
      try {
        const responce = await chapterService.getCourseAllChaptersWithQuiz(
          courseId,
          profileId
        )
        chapters.value = responce.data
        calculatePercentage()
        generateQuizForm(0)
        navigateToUnreadChapter()
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const playVoice = async text => {
      if (text === null || text === '') {
        return false
      } else {
        text = text.toString()
      }
      text = text.replace(/(<([^>]+)>)/gi, '')
      responsiveVoice.speak(text)
      playFlag.value = false
      pauseFlag.value = true
      stopFlag.value = true
      resumeFlag.value = false
    }
    const pauseVoice = async () => {
      responsiveVoice.pause()
      resumeFlag.value = true
      stopFlag.value = true
      playFlag.value = false
      pauseFlag.value = false
    }
    const resumeVoice = async () => {
      responsiveVoice.resume()
      pauseFlag.value = true
      stopFlag.value = true
      resumeFlag.value = false
      playFlag.value = false
    }
    const stopVoice = async () => {
      responsiveVoice.cancel()
      playFlag.value = true
      pauseFlag.value = false
      stopFlag.value = false
      resumeFlag.value = false
    }
    const nextChapter = async (index, chapterId) => {
      scrollTo('course-details')
      await stopVoice()
      activeCourseIndex.value++
      const percentage = 100 / chapters.value.length
      coursePercentage.value = +(coursePercentage.value + percentage).toFixed(
        1
      )
      if (coursePercentage.value > 100) coursePercentage.value = 100
      activeMenu.value = index + 1
      if (!chapters.value[index].read) {
        await readChapter(chapterId)
      }
      if (
        questionForm.quiz.length > 0 &&
        attendedQuestions.value.length === totalQuestions.value
      ) {
        await validateAnswers(index)
      }
      await generateQuizForm(index + 1)
      if (chapters.value.length === activeMenu.value) {
        courseCompleted.value = true
        enableSideMenuClick.value = true
        previewCourse.value = false
        const profile = commonServices.getCurrentProfile()
        await getCourseAllChaptersWithQuiz(courseId.value, profile._id)
        if (attempt.value.mark >= 80) {
          await generateCertficate(attempt.value.mark)
        }
      }
    }
    const previouseChapter = async index => {
      scrollTo('course-details')
      await stopVoice()
      activeCourseIndex.value--
      const percentage = 100 / chapters.value.length
      coursePercentage.value = +(coursePercentage.value - percentage).toFixed(
        1
      )
      if (coursePercentage.value > 100) coursePercentage.value = 100
      activeMenu.value = index - 1
      generateQuizForm(index - 1)
      disableNext.value = false
    }
    const menuClicked = async index => {
      enableSideMenuClick.value = true
      previewCourse.value = true
      activeMenu.value = index
      activeCourseIndex.value = index
      const percentage = 100 / chapters.value.length
      coursePercentage.value = +(percentage * (index + 1)).toFixed(1)
      if (coursePercentage.value > 100) coursePercentage.value = 100
      await generateQuizForm(index)
    }
    const finish = async () => {
      router.push('/candidate/course' + loginAs.value)
    }
    const saveSelectedAnswer = async (quizId, questionId, options) => {
      try {
        let calculateResult = false
        if (!attendedQuestions.value.includes(questionId)) {
          attendedQuestions.value.push(questionId)
        }
        // Enable next chapter button
        if (attendedQuestions.value.length === totalQuestions.value) {
          disableNext.value = false
          calculateResult = true
        }
        const profile = commonServices.getCurrentProfile()
        const data = {
          profile: profile._id,
          quiz: quizId,
          question: questionId,
          answers: []
        }
        const validateData = { question: questionId, answers: [] }
        if (previousQuestionId.value === questionId) {
          validateAnswerData.value.questions.pop()
        }
        for (const option of options) {
          data.answers.push({ option: option })
          validateData.answers.push({ correct: false, option: option })
        }
        validateAnswerData.value.questions.push(validateData)

        await quizService.saveQuestionAttemptAnswer(data)

        if (calculateResult) {
          // Calculate result using API
        }
        previousQuestionId.value = questionId
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const getCourseDetails = async () => {
      try {
        const responce = await courseService.getCourseDetails(courseId.value)
        course.value = responce.data
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const reAttemptTest = async () => {
      try {
        const chapter = chapters.value[chapters.value.length - 1]
        const quizzes: any = chapter.quizzes
        const profile = commonServices.getCurrentProfile()
        for (const quiz of quizzes) {
          await quizService.reattemptQuiz({
            profileId: profile._id,
            quizId: quiz._id,
            chapterId: chapter._id
          })
        }
        await menuClicked(chapters.value.length - 1)
        // Reset previously selected options
        for (const quiz of questionForm.quiz) {
          for (const question of quiz.questions) {
            question.correct = []
          }
        }
        courseCompleted.value = false
        enableSideMenuClick.value = false
        previewCourse.value = false
        disableNext.value = true
        validateAnswerData.value.questions = []
        attendedQuestions.value = []
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    onMounted(() => {
      const profile = commonServices.getCurrentProfile()
      getCourseAllChaptersWithQuiz(courseId.value, profile._id)
      getCourseDetails()
    })
    return {
      chapters,
      activeCourseIndex,
      nextChapter,
      previouseChapter,
      coursePercentage,
      calculatePercentage,
      activeMenu,
      menuClicked,
      questionFormRef,
      questionForm,
      finish,
      course,
      saveSelectedAnswer,
      disableNext,
      courseCompleted,
      enableSideMenuClick,
      previewCourse,
      loading,
      attempt,
      reAttemptTest,
      playVoice,
      pauseVoice,
      resumeVoice,
      stopVoice,
      playFlag,
      pauseFlag,
      resumeFlag,
      stopFlag,
      loginAs
    }
  }
})
