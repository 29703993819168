<template>
  <div class="main">
    <div class="single-CourseList content-body">
      <a-breadcrumb>
        <a-breadcrumb-item href="">
          <HomeOutlined />
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <router-link :to="'/candidate/course' + loginAs">Courses</router-link>
        </a-breadcrumb-item>
      </a-breadcrumb>
      <div class="body">
        <a-row class="courses-list" :gutter="24">
          <a-col
            :xs="24"
            :md="15"
            :lg="16"
            :xl="18"
            class="gutter-row course-details"
            id="course-details"
          >
            <div v-for="(chapter, index) in chapters" :key="index">
              <div v-if="index == activeCourseIndex">
                <div class="header">
                  <div class="coursetitle">{{ course?.title }}</div>
                  <div class="title">{{ chapter.title }}</div>
                </div>
                <div class="subhead">
                  <div class="voice-action">
                    <a-button
                      type="primary"
                      ghost
                      @click="playVoice(chapter.content)"
                      v-if="playFlag"
                      ><PlayCircleOutlined /> Play</a-button
                    >
                    <a-button
                      type="primary"
                      ghost
                      @click="pauseVoice()"
                      v-if="pauseFlag"
                      ><PauseCircleOutlined /> Pause</a-button
                    >
                    <a-button
                      type="primary"
                      ghost
                      @click="resumeVoice()"
                      v-if="resumeFlag"
                      ><RedoOutlined /> Resume</a-button
                    >
                    <a-button
                      type="primary"
                      ghost
                      @click="stopVoice()"
                      v-if="stopFlag"
                      ><StopOutlined /> Stop</a-button
                    >
                  </div>
                  <a-progress :percent="coursePercentage" />
                </div>
                <div class="course-body-content">
                  <div class="renderContent" v-html="chapter.content"></div>
                  <div
                    class="assesment"
                    v-for="(quiz, qindex) in questionForm.quiz"
                    :key="qindex"
                  >
                    <div class="title">
                      <img
                        src="./../../../assets/images/Group3195.svg"
                        alt="category"
                        srcset=""
                      />{{ quiz.name }}
                    </div>
                    <a-form
                      ref="questionFormRef"
                      :model="quiz"
                      :hideRequiredMark="true"
                      layout="vertical"
                    >
                      <div class="QAsection">
                        <a-form-item
                          v-for="(question, index) in quiz.questions"
                          :key="question._id"
                        >
                          <div class="question">
                            <span class="qCount">{{ index + 1 }}</span>
                            <div v-html="question.question"></div>
                          </div>
                          <a-radio-group
                            v-model:value="question.correct[0]"
                            @change="
                              saveSelectedAnswer(
                                quiz._id,
                                question._id,
                                question.correct
                              )
                            "
                            v-if="question.type == 'Single Choice'"
                          >
                            <a-radio
                              :value="option._id"
                              v-for="option in question.options"
                              :key="option._id"
                              :disabled="courseCompleted"
                              :class="option.higlightClass"
                              >{{ option.name }}</a-radio
                            >
                          </a-radio-group>
                          <a-checkbox-group
                            v-model:value="question.correct"
                            @change="
                              saveSelectedAnswer(
                                quiz._id,
                                question._id,
                                question.correct
                              )
                            "
                            v-else
                          >
                            <a-checkbox
                              :value="option._id"
                              v-for="option in question.options"
                              :key="option._id"
                              :disabled="courseCompleted"
                              :class="option.higlightClass"
                              >{{ option.name }}</a-checkbox
                            >
                          </a-checkbox-group>
                        </a-form-item>
                      </div>
                    </a-form>
                  </div>
                </div>
                <div class="footerBtns">
                  <a-button
                    class="prev"
                    v-if="activeCourseIndex != 0"
                    @click="previouseChapter(index)"
                    >Back</a-button
                  >
                  <a-button
                    class="next"
                    @click="nextChapter(index, chapter._id)"
                    :disabled="disableNext"
                    :loading="loading"
                    >Next Section</a-button
                  >
                </div>
              </div>
            </div>
            <div
              class="courseCompleted"
              v-if="courseCompleted && !previewCourse"
            >
              <div class="test-pass" v-if="attempt?.mark >= 80">
                <div class="icon">
                  <HeartOutlined
                    :style="{ fontSize: '30px', color: '#ff7875' }"
                  />
                </div>
                <div class="title">Thank You! {{ attempt?.mark }}</div>
                <div class="message">
                  You have completed the course (<b>{{ course?.title }}</b
                  >) successfully!. You can preview the course from chapter
                  list.
                </div>
                <div class="action">
                  <a-button type="primary"
                    ><router-link :to="'/candidate/course' + loginAs"
                      ><UnorderedListOutlined /> Course</router-link
                    ></a-button
                  >
                </div>
              </div>
              <div class="exam-fail" v-else>
                <div class="icon">
                  <WarningOutlined
                    :style="{ fontSize: '30px', color: '#ff7875' }"
                  />
                </div>
                <div class="title">Test Failed</div>
                <div class="message">
                  You have finished the course (<b>{{ course?.title }}</b
                  >) but you have failed in test. Please re-attempt the test to
                  complete the course.
                </div>
                <div class="action">
                  <a-button type="primary" @click="reAttemptTest"
                    ><RedoOutlined /> Re-attempt Test</a-button
                  >
                </div>
              </div>
            </div>
          </a-col>
          <a-col :xs="24" :md="9" :lg="8" :xl="6" class="gutter-row listMenu">
            <div class="header">
              <div class="firsSec">
                <img
                  src="./../../../assets/images/archive.svg"
                  alt="category"
                  srcset=""
                />
                <span>{{ course?.title }}</span>
              </div>
              <div class="secSec">
                <div class="chapters">
                  Chapters<span>{{ chapters.length }}</span>
                </div>
                <div class="completed">
                  Completed<span>{{ coursePercentage }} %</span>
                </div>
              </div>
            </div>
            <ul v-if="!enableSideMenuClick">
              <li
                v-for="(course, index) in chapters"
                :key="index"
                :class="{ active: activeMenu === index }"
              >
                <p>
                  <span class="count">{{ index + 1 }}</span>
                  <span class="text">{{ course.title }}</span>
                  <span class="icn"><CaretRightFilled /></span>
                  <span class="icn1"><CheckOutlined /></span>
                </p>
              </li>
            </ul>
            <ul v-else>
              <li
                v-for="(course, index) in chapters"
                :key="index"
                :class="{ active: activeMenu === index }"
                @click="menuClicked(index)"
              >
                <p>
                  <span class="count">{{ index + 1 }}</span>
                  <span class="text">{{ course.title }}</span>
                  <span class="icn"><CaretRightFilled /></span>
                  <span class="icn1"><CheckOutlined /></span>
                </p>
              </li>
            </ul>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, reactive, UnwrapRef } from 'vue'
import {
  HomeOutlined,
  HeartOutlined,
  UnorderedListOutlined,
  WarningOutlined,
  RedoOutlined,
  PlayCircleOutlined,
  StopOutlined,
  PauseCircleOutlined,
  CaretRightFilled,
  CheckOutlined
} from '@ant-design/icons-vue'
import { useRoute } from 'vue-router'
import { notification } from 'ant-design-vue'
import moment from 'moment'

import router from '../../../router'
import chapterService from '../../../services/chapter'
import courseService from '../../../services/course'
import quizService from '../../../services/quiz'
import certficateService from '../../../services/certficate'
import commonServices from '../../../services/common'

import IChapter from './../../../interface/chapter'
import ICourse from './../../../interface/course'

declare const responsiveVoice

interface Answer {
  _id?: string;
  name: string;
  correct?: boolean;
  higlightClass?: string;
}

interface Question {
  _id?: string;
  question: string;
  options: Answer[];
  type: string;
  correct?: [];
}
interface Quiz {
  _id?: string;
  name: string;
  questions: Question[];
}
interface QuizForm {
  quiz: Quiz[];
}
export default defineComponent({
  components: {
    HomeOutlined,
    UnorderedListOutlined,
    HeartOutlined,
    WarningOutlined,
    RedoOutlined,
    PlayCircleOutlined,
    StopOutlined,
    PauseCircleOutlined,
    CaretRightFilled,
    CheckOutlined
  },
  setup () {
    const params = new URLSearchParams(window.location.search).get('loginAs')
    const loginAs = ref(params ? '?loginAs=' + params : '')
    const chapters = ref<Array<IChapter>>([])
    const course = ref<Array<ICourse>>([])
    const route = useRoute()
    const courseId = ref<string>(route.params.id as string)
    const activeCourseIndex = ref(0)
    const coursePercentage = ref(0)
    const disableNext = ref<boolean>(false)
    const courseCompleted = ref<boolean>(false)
    const enableSideMenuClick = ref<boolean>(false)
    const previewCourse = ref<boolean>(false)
    const loading = ref<boolean>(false)
    const activeMenu = ref(0)
    const questionFormRef = ref()
    const attendedQuestions = ref([])
    const validateAnswerData = ref({ questions: [] })
    const totalQuestions = ref(0)
    const previousQuestionId = ref<string>()
    const validatedResponce = ref([])
    const attempt = ref()
    const playFlag = ref<boolean>(true)
    const pauseFlag = ref<boolean>(false)
    const resumeFlag = ref<boolean>(false)
    const stopFlag = ref<boolean>(false)
    const notify = async (
      message: string,
      description: string,
      type: string
    ) => {
      notification[type]({
        message,
        description
      })
    }
    const questionForm: UnwrapRef<QuizForm> = reactive({
      quiz: []
    })
    const scrollTo = async (id) => {
      document.getElementById(id).scrollIntoView({
        behavior: 'smooth'
      })
    }
    const readChapter = async chapterId => {
      try {
        const profile = commonServices.getCurrentProfile()
        const data = {
          profile: profile._id,
          course: courseId.value,
          chapter: chapterId
        }
        await chapterService.readChapter(data)
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const validateAnswers = async (index?) => {
      try {
        loading.value = true
        const profile = commonServices.getCurrentProfile()
        if (index) {
          for (const quiz of chapters.value[index].quizzes) {
            const chapterQuiz: any = quiz
            const responce = await quizService.validateAnswers(
              validateAnswerData.value,
              chapterQuiz._id,
              profile._id
            )
            validatedResponce.value = responce.data.output
            attempt.value = responce.data.attempt
            for (const question of chapterQuiz.questions) {
              question.question = question.name
              const correctAnswer: any = []
              const q = validatedResponce.value.filter(
                a => a.question === question._id
              )
              if (question.attemptOption && question.attemptOption.length > 0) {
                for (const o of question.attemptOption) {
                  correctAnswer.push(o.option)
                }
              }
              if (q.length > 0) {
                for (const option of question.options) {
                  const answer = q[0].answers.filter(
                    b => b.option === option._id
                  )
                  if (answer.length > 0) {
                    if (question.type === 'Single Choice') {
                      if (option._id === answer[0].option) {
                        option.correct = true
                        if (attempt.value.mark >= 80) {
                          option.higlightClass = 'correct'
                        }
                      }
                    } else {
                      for (const a of answer) {
                        if (option._id === a.option) {
                          option.correct = true
                          if (attempt.value.mark >= 80) {
                            option.higlightClass = 'correct'
                          }
                        }
                      }
                    }
                  }
                }
              }
              question.correct = correctAnswer
            }
            questionForm.quiz = []
            questionForm.quiz.push(quiz)
          }
        }
        loading.value = false
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const generateQuizForm = async index => {
      validateAnswerData.value.questions = []
      totalQuestions.value = 0
      if (
        chapters.value[index] &&
        chapters.value[index].quizzes &&
        chapters.value[index].quizzes.length > 0
      ) {
        for (const quiz of chapters.value[index].quizzes) {
          const chapterQuiz: any = quiz
          const questions: Question[] = []
          for (const question of chapterQuiz.questions) {
            disableNext.value = true
            totalQuestions.value++
            const options: Answer[] = []
            for (const option of question.options) {
              options.push({
                _id: option._id,
                name: option.name,
                correct: false,
                higlightClass: ''
              })
            }
            const correctAnswer: any = []
            if (question.attemptOption && question.attemptOption.length > 0) {
              attendedQuestions.value.push(question._id)
              const validateData = { question: question._id, answers: [] }
              for (const option of question.attemptOption) {
                correctAnswer.push(option.option)
                validateData.answers.push({
                  correct: false,
                  option: option.option,
                  higlightClass: ''
                })
              }
              validateAnswerData.value.questions.push(validateData)
            }
            questions.push({
              _id: question._id,
              question: question.name,
              options: options,
              type: question.type,
              correct: correctAnswer
            })
          }
          const q: Quiz = {
            _id: chapterQuiz._id,
            name: chapterQuiz.name,
            questions: questions
          }
          questionForm.quiz.push(q)
        }
        // get validated answers
        await validateAnswers(index)
        // Enable next chapter button
        if (attendedQuestions.value.length === totalQuestions.value) {
          disableNext.value = false
        }
      } else {
        questionForm.quiz = []
      }
    }
    const generateCertficate = async mark => {
      try {
        const profile = commonServices.getCurrentProfile()
        await certficateService.generateCertficate({
          course: courseId.value,
          profile: profile._id,
          date: moment.utc().format(),
          exp_date: moment.utc().add(1, 'year'),
          grade: mark
        })
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const calculatePercentage = async () => {
      coursePercentage.value = +(100 / chapters.value.length).toFixed(1)
      if (coursePercentage.value > 100) coursePercentage.value = 100
    }
    const navigateToUnreadChapter = async () => {
      let i = 0
      for (const chapter of chapters.value) {
        if (chapter.read) {
          activeCourseIndex.value++
          activeMenu.value = i + 1
          const percentage = 100 / chapters.value.length
          coursePercentage.value = +(
            coursePercentage.value + percentage
          ).toFixed(1)
          if (coursePercentage.value > 100) coursePercentage.value = 100
          await generateQuizForm(i + 1)
        }
        i++
      }
      if (chapters.value.length === activeMenu.value) {
        courseCompleted.value = true
        enableSideMenuClick.value = true
        previewCourse.value = false
      }
    }
    const getCourseAllChaptersWithQuiz = async (courseId, profileId) => {
      try {
        const responce = await chapterService.getCourseAllChaptersWithQuiz(
          courseId,
          profileId
        )
        chapters.value = responce.data
        calculatePercentage()
        generateQuizForm(0)
        navigateToUnreadChapter()
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const playVoice = async text => {
      if (text === null || text === '') {
        return false
      } else {
        text = text.toString()
      }
      text = text.replace(/(<([^>]+)>)/gi, '')
      responsiveVoice.speak(text)
      playFlag.value = false
      pauseFlag.value = true
      stopFlag.value = true
      resumeFlag.value = false
    }
    const pauseVoice = async () => {
      responsiveVoice.pause()
      resumeFlag.value = true
      stopFlag.value = true
      playFlag.value = false
      pauseFlag.value = false
    }
    const resumeVoice = async () => {
      responsiveVoice.resume()
      pauseFlag.value = true
      stopFlag.value = true
      resumeFlag.value = false
      playFlag.value = false
    }
    const stopVoice = async () => {
      responsiveVoice.cancel()
      playFlag.value = true
      pauseFlag.value = false
      stopFlag.value = false
      resumeFlag.value = false
    }
    const nextChapter = async (index, chapterId) => {
      scrollTo('course-details')
      await stopVoice()
      activeCourseIndex.value++
      const percentage = 100 / chapters.value.length
      coursePercentage.value = +(coursePercentage.value + percentage).toFixed(
        1
      )
      if (coursePercentage.value > 100) coursePercentage.value = 100
      activeMenu.value = index + 1
      if (!chapters.value[index].read) {
        await readChapter(chapterId)
      }
      if (
        questionForm.quiz.length > 0 &&
        attendedQuestions.value.length === totalQuestions.value
      ) {
        await validateAnswers(index)
      }
      await generateQuizForm(index + 1)
      if (chapters.value.length === activeMenu.value) {
        courseCompleted.value = true
        enableSideMenuClick.value = true
        previewCourse.value = false
        const profile = commonServices.getCurrentProfile()
        await getCourseAllChaptersWithQuiz(courseId.value, profile._id)
        if (attempt.value.mark >= 80) {
          await generateCertficate(attempt.value.mark)
        }
      }
    }
    const previouseChapter = async index => {
      scrollTo('course-details')
      await stopVoice()
      activeCourseIndex.value--
      const percentage = 100 / chapters.value.length
      coursePercentage.value = +(coursePercentage.value - percentage).toFixed(
        1
      )
      if (coursePercentage.value > 100) coursePercentage.value = 100
      activeMenu.value = index - 1
      generateQuizForm(index - 1)
      disableNext.value = false
    }
    const menuClicked = async index => {
      enableSideMenuClick.value = true
      previewCourse.value = true
      activeMenu.value = index
      activeCourseIndex.value = index
      const percentage = 100 / chapters.value.length
      coursePercentage.value = +(percentage * (index + 1)).toFixed(1)
      if (coursePercentage.value > 100) coursePercentage.value = 100
      await generateQuizForm(index)
    }
    const finish = async () => {
      router.push('/candidate/course' + loginAs.value)
    }
    const saveSelectedAnswer = async (quizId, questionId, options) => {
      try {
        let calculateResult = false
        if (!attendedQuestions.value.includes(questionId)) {
          attendedQuestions.value.push(questionId)
        }
        // Enable next chapter button
        if (attendedQuestions.value.length === totalQuestions.value) {
          disableNext.value = false
          calculateResult = true
        }
        const profile = commonServices.getCurrentProfile()
        const data = {
          profile: profile._id,
          quiz: quizId,
          question: questionId,
          answers: []
        }
        const validateData = { question: questionId, answers: [] }
        if (previousQuestionId.value === questionId) {
          validateAnswerData.value.questions.pop()
        }
        for (const option of options) {
          data.answers.push({ option: option })
          validateData.answers.push({ correct: false, option: option })
        }
        validateAnswerData.value.questions.push(validateData)

        await quizService.saveQuestionAttemptAnswer(data)

        if (calculateResult) {
          // Calculate result using API
        }
        previousQuestionId.value = questionId
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const getCourseDetails = async () => {
      try {
        const responce = await courseService.getCourseDetails(courseId.value)
        course.value = responce.data
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const reAttemptTest = async () => {
      try {
        const chapter = chapters.value[chapters.value.length - 1]
        const quizzes: any = chapter.quizzes
        const profile = commonServices.getCurrentProfile()
        for (const quiz of quizzes) {
          await quizService.reattemptQuiz({
            profileId: profile._id,
            quizId: quiz._id,
            chapterId: chapter._id
          })
        }
        await menuClicked(chapters.value.length - 1)
        // Reset previously selected options
        for (const quiz of questionForm.quiz) {
          for (const question of quiz.questions) {
            question.correct = []
          }
        }
        courseCompleted.value = false
        enableSideMenuClick.value = false
        previewCourse.value = false
        disableNext.value = true
        validateAnswerData.value.questions = []
        attendedQuestions.value = []
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    onMounted(() => {
      const profile = commonServices.getCurrentProfile()
      getCourseAllChaptersWithQuiz(courseId.value, profile._id)
      getCourseDetails()
    })
    return {
      chapters,
      activeCourseIndex,
      nextChapter,
      previouseChapter,
      coursePercentage,
      calculatePercentage,
      activeMenu,
      menuClicked,
      questionFormRef,
      questionForm,
      finish,
      course,
      saveSelectedAnswer,
      disableNext,
      courseCompleted,
      enableSideMenuClick,
      previewCourse,
      loading,
      attempt,
      reAttemptTest,
      playVoice,
      pauseVoice,
      resumeVoice,
      stopVoice,
      playFlag,
      pauseFlag,
      resumeFlag,
      stopFlag,
      loginAs
    }
  }
})
</script>

<style lang="scss">
.single-CourseList {
  .body {
    background: #ffffff;
    min-height: 200px;
    box-shadow: 0px 5px 10px #00000003;
    border-radius: 8px;
    width: 100%;
    margin: 5px 0 0 0;
    .course-details {
      padding: 20px 35px !important;
      border-right: 1px solid #e1e1e1;
      .header {
        display: flex;
        justify-content: space-between;
        padding: 0 0 10px 0;
        border-bottom: 1px solid #e1e1e1;
        .coursetitle {
          color: #305299;
          font-family: "TT Norms Pro Medium";
          font-size: 18px;
          text-align: left;
        }
        .title {
          color: #305299;
          font-family: "TT Norms Pro Medium";
          font-size: 16px;
          text-align: left;
          text-transform: capitalize;
        }
      }
      .subhead {
        display: flex;
        justify-content: space-between;
        padding: 20px 0;
        .voice-action {
          width: 50%;
          text-align: left;
          padding-bottom: 20px;
          button {
            margin-right: 10px;
            background-color: #305299 !important;
            border: none;
            color: #fff;
            border-radius: 5px;
          }
        }
        .ant-progress-line {
          & > div {
            display: flex;
          }
          width: 50%;
          .ant-progress-success-bg,
          .ant-progress-bg {
            background-color: #305299;
            height: 15px !important;
          }
          .ant-progress-text {
            background-color: #ffc788;
            color: #fff;
            border-radius: 7px;
            padding: 5px 10px;
            width: auto;
          }
        }
      }
      .renderContent {
        text-align: left;
        font-family: "TT Norms Pro Regular";
        font-size: 14px;
        color: #000000;
        line-height: 2;
        p {
          text-align: left !important;
        }
      }
      .course-body-content {
        // height: 400px;
        // overflow-y: scroll;
        overflow-x: hidden;
        @extend .overflowstyle;
      }
      .footerBtns {
        border-top: 1px solid #e6e3e3;
        padding: 20px 0 0 0;
        display: flex;
        justify-content: flex-end;
        .ant-btn {
          background-color: #72cf97;
          color: #fff;
          border-radius: 20px;
          font-family: "TT Norms Pro Regular";
          font-size: 14px;
          text-shadow: none;
          border: none;
          box-shadow: none;
          &.prev {
            border: 2px solid #72cf97;
            color: #707070;
            background-color: transparent;
            margin: 0 20px 0 0;
          }
        }
      }
      .assesment {
        .title {
          color: #305299;
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-bottom: 1px solid #e2e2e2d6;
          width: fit-content;
          margin: auto;
          padding: 0 0 10px 0;
          img {
            width: 50px;
          }
        }
        .ant-form {
          padding: 20px 20px 0 0;
        }
      }
      .QAsection {
        .ant-form-item {
          padding: 15px 25px;
          border-radius: 8px;
          border: 2px solid #4f74c1;
        }
        .question {
          color: #000000;
          font-family: "TT Norms Pro Medium";
          margin: 0 0 10px 0;
          display: flex;
          text-align: left;
          align-items: center;
          .qCount {
            background-color: #5d88e1;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 10px 0 0;
          }
          p {
            margin: 0;
          }
        }
        .ant-checkbox-group,
        .ant-radio-group {
          width: 100%;
          .correct {
            border: 2px solid #72cf97;
            width: fit-content;
            .ant-checkbox + span,
            span.ant-radio + * {
              color: #72cf97;
            }
            .ant-checkbox-inner,
            .ant-radio-inner {
              border: 2px solid #72cf97;
            }
            .ant-checkbox-checked .ant-checkbox-inner::after {
              border: 2px solid #72cf97;
              border-top: 0;
              border-left: 0;
            }
            .ant-radio-checked .ant-radio-inner::after {
              background-color: #72cf97;
            }
          }
          .wrong {
            background-color: #f44336;
            border-color: #f44336;
            .ant-checkbox + span,
            span.ant-radio + * {
              color: #fff;
            }
          }
          label {
            margin: 0 20px 15px 0;
            width: 100%;
            border-radius: 20px;
            display: flex;
            padding: 0 5px;
            align-items: center;
            min-height: 30px;
            .ant-checkbox-inner,
            .ant-radio-inner {
              background: #fff;
              border: 2px solid #5d88e1;
            }
            .ant-checkbox-checked .ant-checkbox-inner,
            .ant-radio-checked .ant-radio-inner {
              // border: none;
            }
            .ant-checkbox-checked::after,
            .ant-radio-checked::after {
              // border: none;
            }
            .ant-checkbox-checked .ant-checkbox-inner::after {
              border: 2px solid #305299;
              border-top: 0;
              border-left: 0;
            }
            span.ant-radio + * {
              white-space: normal;
              padding-left: 17px;
            }
            .ant-radio-checked .ant-radio-inner::after {
              background-color: #305299;
              top: 2px;
              left: 2px;
            }
            .ant-radio-input:focus + .ant-radio-inner {
              box-shadow: none;
            }
          }
        }
      }
      .courseCompleted {
        .icon {
          padding-top: 40px;
          i {
            font-size: 30px;
            color: #ff4d4f;
          }
        }
        .title {
          font-size: 20px;
          padding: 13px;
          font-family: "TT Norms Pro Bold";
          text-align: center;
        }
        .message {
          padding: 0 0 10px;
          font-size: 17px;
        }
        .action {
          button {
            background: #7EAF1A;
            border: 1px solid #7EAF1A;
            border-radius: 20px;
            width: auto;
          }
        }
      }
    }
    .listMenu {
      padding: 25px 12px;
      .header {
        background-color: #305299;
        box-shadow: 0px 16px 40px #30529963;
        border-radius: 8px;
        margin: 0px 15px 35px 4px;
        padding: 10px;
        .firsSec {
          border-bottom: 1px solid #6f8ac3;
          padding: 0 0 10px 0;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 45px;
          }
          span {
            color: #fff;
            font-size: 15px;
            margin: 0px 0 0 15px;
          }
        }
        .secSec {
          display: flex;
          color: #fff;
          padding: 5px 0 0 0;
          .chapters,
          .completed {
            width: 40%;
            font-size: 12px;
            display: flex;
            align-items: flex-end;
            span {
              font-size: 30px;
              line-height: normal;
              padding: 0 0 0 5px;
            }
          }
          .completed {
            width: 60%;
            padding: 0 0 0 10px;
            span {
              color: #81ebab;
              font-size: 20px;
            }
          }
        }
      }
      ul {
        list-style: none;
        padding: 0;
        li {
          background-color: #3052990d;
          color: #305299;
          padding: 5px 5px;
          display: flex;
          min-height: 40px;
          margin: 0 15px 20px 4px;
          cursor: pointer;
          border-radius: 8px;
          transition: all 0.3s ease-in-out;
          p {
            margin: 0;
            line-height: normal;
            font-family: "TT Norms Pro Regular";
            font-size: 14px;
            display: flex;
            align-items: center;
            width: 100%;
            .count {
              background-color: #305299;
              color: #fff;
              height: 30px;
              width: 30px;
              font-size: 12px;
              border-radius: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 0 10px 0 0;
              font-family: "TT Norms Pro Medium";
            }
            .text {
              width: calc(100% - 70px);
              text-align: left;
            }
            .icn {
              width: 25px;
              height: 25px;
              background: #fff;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .icn1 {
              width: 25px;
              height: 25px;
              background: #fff;
              color: #72cf97;
              border-radius: 50%;
              display: none;
              align-items: center;
              justify-content: center;
            }
          }
          &.active {
            background-color: #72cf9730;
            p {
              .count {
                background-color: #72cf97;
              }
              .icn {
                display: none;
              }
              .icn1 {
                display: flex;
              }
            }
          }
        }
      }
    }
  }
}
.overflowstyle {
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #305299;
  }
}
@media only screen and (max-width: 768px) {
  .single-CourseList {
    .courses-list {
      flex-direction: column-reverse;
    }
  }
}
@media only screen and (max-width: 500px) {
  .single-CourseList {
    .body .listMenu ul {
      padding-left: 15px;
    }
    .body .course-details .footerBtns {
      flex-direction: column;
      .ant-btn {
        width: 100%;
        &.prev {
          margin: 0 0px 15px 0;
        }
      }
    }
  }
}
</style>
